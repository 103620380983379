import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var react_1 = _react;

var toggleReducer = function (state, nextValue) {
  return typeof nextValue === "boolean" ? nextValue : !state;
};

var useToggle = function (initialValue) {
  return react_1.useReducer(toggleReducer, initialValue);
};

exports.default = useToggle;
export default exports;
export const __esModule = exports.__esModule;